// menu fixo
$("document").ready(function($){
    num = $('.navbar').offset().top;
    var nav = $('.navbar');

    $(window).scroll(function () {
        if ($(this).scrollTop() >= num) {
            nav.addClass("fixed-nav");
        } else {
            nav.removeClass("fixed-nav");
        }
    });
});

// galeria
$('#owl-gallery').owlCarousel({
    items: 1,
    margin: 50,
    autoplay: true,
    nav: false,
    navText: ["<img src='../user/themes/poncho/images/icons/prev.png'>", "<img src='../user/themes/poncho/images/icons/next.png'>"],
    responsiveClass:true,
    responsive:{
        0:{
            items: 1,
        },
        600:{
            items: 2,
        },
        1000:{
            items: 3,
            nav: true,
            dots: false,
        }
    }
})

$('#sobre-gallery').owlCarousel({
    items: 1,
    margin: 50,
    autoplay: true,
    nav: false,
    navText: ["<img src='./user/themes/poncho/images/icons/prev.png'>", "<img src='./user/themes/poncho/images/icons/next.png'>"],
    responsiveClass:true,
    responsive:{
        0:{
            items: 1,
        },
        600:{
            items: 2,
        },
        1000:{
            items: 3,
            nav: true,
            dots: false,
        }
    }
})

$('#home-gallery').owlCarousel({
    items: 1,
    margin: 0,
    autoplay: true,
    dots: false,
    nav: false,
    navText: ["<img src='./user/themes/poncho/images/icons/home/prev.png'>", "<img src='./user/themes/poncho/images/icons/home/next.png'>"],
    responsiveClass:true,
    mouseDrag: false,
    responsive:{
        1000:{
            nav: true,
        }
    }
})

jQuery(function($){

    // Last word in title
    $('.gallery-item .title').html(function(){    
        var text = $(this).text().split(' ');
        var last = text.pop();
        return text.join(" ") + (text.length > 0 ? ' <span class="last-word">'+last+'</span>' : last);   
    });
});

$(document).ready(function($) {
    var Body = $('body');
    Body.addClass('preloader-site');
});
$(window).load(function() {
    $('.preloader-wrapper').fadeOut();
    $('body').removeClass('preloader-site');
});